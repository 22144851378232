<template>
  <div class="my">
    <my-header :num="num" :data="myData" :isLogin="isLogin"></my-header>
    <my-income :data="promotionData"></my-income>
    <my-img></my-img>
    <my-util></my-util>
    <my-com></my-com>
    <div style="height:49px;"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import MyHeader from './childCmps/MyHeader'
import MyIncome from './childCmps/MyIncome'
import MyImg from './childCmps/MyImg'
import MyUtil from './childCmps/MyUtil'
import MyCom from './childCmps/MyCom'

import {dashboard,calculate_promotions,unread_count} from 'network/igou'
import {PromotionBean} from 'network/bean'

export default {
  name: 'home',
  data() {
    return {
      num: 0,
      promotionData:{},
      myData: {},
      isLogin:false
    }
  },
  components: {
    MyHeader,
    MyIncome,
    MyImg,
    MyUtil,
    MyCom
  },
  methods: {
    initData() {
        dashboard().then(res=>{
          console.log(res)
          this.myData = res.data
          this.isLogin = true;
        });
        calculate_promotions().then(res=>{
          this.promotionData = res.data
        });
        unread_count().then(res=>{
          this.num = res.data.count
        });
    }
  },
  mounted() {
    //15007708410    h123456789
    this.initData();
  }
}
</script>
<style  scoped>
  
</style>
