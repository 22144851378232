<template>
  <div class="row flex-center">
        <img class="banner" src="~images/banner.png" alt="">
    </div>
</template>

<script>
  //import GridView from '@/components/common/gridView/GridView'

  export default {
    name: "MyImg",
    components: {
      
    },
    props: {
      
    }
  }
</script>

<style scoped>
.banner{
    width: 9.36rem; 
    height: 2.4rem;
}
</style>
