<template>
  <div class="column f-333333 whitescreen mt20 gongju">
      <span class="row  ml25 font28 h72" style="align-items: flex-end;">必备工具</span>
      <ul class="row align-center h146">
          <li class="flex1">
              <div class="a row align-center" @click="onOrder">
                  <img class="imgsize44" src="~images/order.png" alt="">
                  <span class="font24">我的订单</span>
              </div>
          </li>
          <li class="flex1">
              <div class="a row align-center" @click="onMyFan">
                  <img class="imgsize44" src="~images/team.png" alt="">
                  <span class="font24">我的粉丝</span>
              </div>
          </li>
          <li class="flex1">
              <div class="a row align-center" @click="onShare">
                  <img class="imgsize44" src="~images/invite.png" alt="">
                  <span class="font24">邀请赚</span>
              </div>
          </li>
          <li class="flex1">
              <div class="a row align-center" @click="onXinshou">
                  <img class="imgsize44" src="~images/jiaoc.png" alt="">
                  <span class="font24">新手教程</span>
              </div>
          </li>
      </ul>
  </div>
</template>

<script>
  //import GridView from '@/components/common/gridView/GridView'

  export default {
    name: "MyUtil",
    methods: {
        onOrder() {
            this.$router.push('/zymyorderturn')
            //this.$router.push('/myorder')
        },
        onMyFan() {
            this.$router.push('/myfan')
        },
        onShare() {
            this.$router.push('/share')
        },
        onXinshou() {
            this.$router.push('/jiaocheng')
        }
    }
  }
</script>

<style scoped>
.gongju ul{
    list-style: none;
    padding: 0;
    margin: 0;
}
.gongju .a{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #333333;
}
</style>
