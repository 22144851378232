<template>
  <div class="column whitescreen">
    <div class="row between align-center" @click="onAuth">
      <div class="row align-center font24 f-333333 ml25 h96">
        <img class="imgsize32 mr16" src="~images/kefu.png" alt />
        <span>实名认证</span>
      </div>
      <img class="imgsize32 mr25" src="~images/nextbtn.png" alt />
    </div>
    <div class="row line"></div>
    <div class="row between align-center" @click="onSug">
      <div class="row align-center font24 f-333333 ml25 h96">
        <img class="imgsize32 mr16" src="~images/kefu.png" alt />
        <span>意见反馈</span>
      </div>
      <img class="imgsize32 mr25" src="~images/nextbtn.png" alt />
    </div>
    <div class="row line"></div>
    <div class="row between align-center" @click="onCang">
      <div class="row align-center font24 f-333333 ml25 h96">
        <img class="imgsize32 mr16" src="~images/kefu.png" alt />
        <span>我的地址</span>
      </div>
      <img class="imgsize32 mr25" src="~images/nextbtn.png" alt />
    </div>
    <div class="row line"></div>
    <div class="row between align-center" @click="onPing" v-if="false">
      <div class="row align-center font24 f-333333 ml25 h96">
        <img class="imgsize32 mr16" src="~images/haoping.png" alt />
        <span>给个好评</span>
      </div>
      <img class="imgsize32 mr25" src="~images/nextbtn.png" alt />
    </div>
    <div class="row line"></div>
  </div>
</template>

<script>
//import GridView from '@/components/common/gridView/GridView'

export default {
  name: "MyCom",
  components: {},
  props: {},
  methods: {
    onSug() {
      this.$router.push("/sug");
    },
    /**现在改为我的地址 */
    onCang() {
      //this.$router.push("/mycollect");
      //this.$toast.show("敬请期待");
      this.$router.push("/zyaddr");
    },
    onPing() {
      this.$toast.show("敬请期待");
    },
    onAuth() {
      this.$router.push("/comfirm");
    }
  }
};
</script>

<style scoped>
</style>
