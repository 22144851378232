<template>
  <div class="column titlebg">
    <div class="row flex-end mt40">
      <img class="imgsize44" src="~images/shezhi.png" alt @click="onSet" />
      <img v-if="num>0" class="imgsize48 ml16 mr25" src="~images/message.png" alt @click="onMes" />
      <img v-else class="imgsize48 ml16 mr25" src="~images/message_un.png" alt @click="onMes" />
    </div>
    
    <div class="row"  v-if="isLogin">
      <img class="imgsize96 br50 ml50" :src="data.avatar.url" alt />
      <div class="column just-center ml16">
        <span class="font28 f-FFFFFF">{{data.name}}</span>
        <div class="row align-center cpbg">
          <span class="font22 f-FED3D9">邀请ID：{{data.invitation_code}}</span>
        </div>
      </div>
    </div>
    <div v-else class="row flex-center" @click="onLogin">
      <img class="loginbtn" src="~images/wode_denglu_but.png" />
    </div>
    <div class="row just-center mt40">
      <div class="row align-center between heidk">
        <div class="column ml25">
          <div class="row baseline f-ECCD84">
            <span class="font24">余额(元)</span>
            <span class="font48">￥{{data.balance || '0.00'}}</span>
          </div>
          <span class="font22 f-31944D">每月25号可提现上月结算收益</span>
        </div>
        <img
          @click="onTx"
          class="mr25"
          style="width: 1.76rem; height: .8rem;"
          src="~images/tixianbtn.png"
          alt
        />
      </div>
    </div>
  </div>
</template>

<script>
//import GridView from '@/components/common/gridView/GridView'

export default {
  name: "MyHeader",
  components: {},
  props: {
    isLogin:Boolean,
    num: {
      type: Number,
      default: 0
    },
    data: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  mounted() {

  },
  computed: {},
  methods: {
    onTx() {
      this.$router.push("/tixian");
    },
    onLogin() {
      this.$router.push("/login");
    },
    onSet() {
      this.$router.push("/setting");
    },
    onMes() {
      this.$router.push("/message");
    }
  }
};
</script>

<style scoped>
.titlebg {
  width: 100%;
  height: 4.266667rem;
  background: url(~images/zhezbg.png) no-repeat;
  background-size: 100% 4.266667rem;
}
.loginbtn {
  width: 3.093333rem;
  height: 1.413333rem;
}
.cpbg {
  width: 3.626667rem;
  height: 0.533333rem;
  background: url(~images/copy.png) no-repeat;
  background-size: 3.626667rem 0.533333rem;
}
.heidk {
  width: 9.36rem;
  height: 1.973333rem;
  background: url(~images/heidikuang.png) no-repeat;
  background-size: 9.36rem 1.973333rem;
}
</style>
