<template>
  <div class="column whitescreen mt100" @click="onShouyi">
        <div class="row align-center h98 ml16">
            <img style="width: .426667rem; height: .426667rem;" src="~images/shouyi.png" alt="">
            <span class="ml16 font28 f-333333">预估收益</span>
        </div>
        <div class="line"></div>
        <div class="row h98">
            <div class="flex1 row flex-between">
                <span class="font24 f-666666 ml25">今日收益</span>
                <span class="font32 f-333333 ml50 mr60">￥{{data.today_amounts || '0.00'}}</span>
            </div>
            <div class="flex1 row flex-between">
                <span class="font24 f-666666 ml25">昨日收益</span>
                <span class="font32 f-333333 ml50 mr25">￥{{data.yesterday_amounts || '0.00'}}</span>
            </div>
        </div>
        <div class="line"></div>
        <div class="row h98">
            <div class="flex1 row flex-between">
                <span class="font24 f-666666 ml25">本月收益</span>
                <span class="font32 f-333333 ml50 mr60">￥{{data.current_month_amounts || '0.00'}}</span>
            </div>
            <div class="flex1 row flex-between">
                <span class="font24 f-666666 ml25">上月收益</span>
                <span class="font32 f-333333 ml50 mr25">￥{{data.last_month_amounts || '0.00'}}</span>
            </div>
        </div>
    </div>
</template>

<script>
  //import GridView from '@/components/common/gridView/GridView'

  export default {
    name: "MyIncome",
    props: {
        data: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    methods: {
        onShouyi() {
            this.$router.push('/shouyi');
        }
    }
   
  }
</script>

<style scoped>

</style>
